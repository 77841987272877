.title {
  color: var(--primary-color-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  margin-bottom: 8px;
}

.avatar {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.cover {
  display: block;
  height: 80px;
  width: 80px;
}

.noAvatar {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--secondary-color-blue-1);
}

.letter {
  font-size: 36px;
}

.uploadPhoto {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;

  color: var(--primary-color-blue);
  background-color: transparent;
  border: none;
  text-decoration: none;

  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
}

.uploadPhoto:hover {
  color: var(--secondary-color-red);
}

.iconUploadPhoto {
  stroke: var(--primary-color-blue);
}

.uploadPhoto:hover .iconUploadPhoto {
  color: var(--secondary-color-red);
  stroke: var(--secondary-color-red);
}

.wrapAvatar {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
  margin-bottom: 24px;
}

.input {
  display: none;
}

@media only screen and (min-width: 768px) {
  .container {
    width: calc(704px - 2 * 24px);
  }
}

@media only screen and (min-width: 1440px) {
  .container {
    width: calc(1008px - 2 * 24px);
  }
}
