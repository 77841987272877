.layout {
  position: relative;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

@media only screen and (min-width: 320px) {
  .container {
    margin: 0 auto;
    max-width: 320px;
    padding: 0px 20px;
    height: 100vh;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 768px;
    padding: 40px 32px 156px 32px;
  }
}
@media screen and (min-width: 1440px) {
  .container {
    max-width: 1440px;
    padding: 148px 198px 232px 198px;
  }
}
