.title {
  color: var(--primary-color-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  margin-bottom: 12px;
}

.radioButtons {
  display: flex;
  align-items: center;

  gap: 24px;
  margin-bottom: 24px;
}

@media only screen and (min-width: 1440px) {
  .radioButtons {
    gap: 24px;
    margin-bottom: 52px;
  }
}
.radiobutton {
  display: none;
}

.labelName {
  position: relative;
  font-size: 16px;
  line-height: 1.25;
  margin-left: 0px;
}

.radiobutton + .labelName::before {
  content: '';
  display: inline-block;
  margin-right: 8px;
  width: 14px;
  height: 14px;
  background: url('../../images/icons/radio-button-circle.svg') no-repeat;
}

.radiobutton:checked + .labelName::before {
  background: url('../../images/icons/radio-button.svg') no-repeat;
  width: 14px;
  height: 14px;
}
