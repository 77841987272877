.title {
  color: var(--primary-color-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  margin-bottom: 24px;
}

.logoutBtn {
  display: block;
  border-radius: 10px;
  background: var(--secondary-color-red);
  box-shadow: 0px 4px 8px 0px rgba(64, 123, 255, 0.34);
  border: transparent;
  padding: 8px 30px;
  width: 232px;
  height: 36px;
  color: var(--primary-color-white);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
}

.logoutBtn:hover {
  box-shadow: 0px 4px 14px 0px #7f232397;
}
.logoutBtn:focus {
  box-shadow: 0px 4px 14px 0px #7f232397;
}

.cancelBtn {
  display: block;
  border-radius: 10px;
  background: var(--secondary-color-blue-2);
  border: transparent;
  padding: 8px 30px;
  width: 232px;
  height: 36px;
  color: var(--primary-color-blue);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
}

.cancelBtn:hover {
  box-shadow: 0px 4px 14px 0px rgba(64, 123, 255, 0.54);
}

.cancelBtn:focus {
  box-shadow: 0px 4px 14px 0px rgba(64, 123, 255, 0.34);
}

@media only screen and (min-width: 768px) {
  .logoutBtn {
    font-size: 18px;
    display: inline;
    width: 160px;
    height: 44px;
    order: 2;
  }

  .cancelBtn {
    font-size: 18px;
    display: inline;
    width: 160px;
    height: 44px;
    order: 1;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .buttons {
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 1440px) {
  .buttons {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 320px) {
  .container {
    width: calc(280px - 2 * 24px);
  }
}

@media only screen and (min-width: 768px) {
  .container {
    width: calc(592px - 2 * 24px);
  }
}
