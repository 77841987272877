@media only screen and (min-width: 1440px) {
  .form {
    display: flex;
    gap: 24px;
  }
}

.formName,
.formEmail {
  position: relative;

  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

@media only screen and (min-width: 1440px) {
  .formEmail {
    margin-bottom: 0;
  }
}

.formPassword {
  position: relative;
  display: flex;
  flex-direction: column;
}

.subform {
  position: relative;
  width: 256px;
}

@media only screen and (min-width: 768px) {
  .subform {
    width: 392px;
  }
}

.title {
  color: var(--primary-color-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  margin-bottom: 8px;
}

.titlePassword {
  color: var(--primary-color-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  margin-bottom: 12px;
}

@media only screen and (min-width: 1440px) {
  .titlePassword {
    margin-bottom: 0;
  }
}

.subTitle {
  color: var(--primary-color-black);
  font-size: 16px;
  line-height: 1.25;
  margin-bottom: 8px;
  margin-top: 12px;
}

.input {
  color: var(--primary-color-blue);
  font-size: 16px;
  line-height: 1.25;

  width: 256px;
  height: 44px;
  padding: 12px 10px;

  border-radius: 6px;
  border: 1px solid var(--secondary-color-blue-2);
}

@media only screen and (min-width: 768px) {
  .input {
    width: 392px;
  }
}

.input:focus {
  outline: var(--secondary-color-blue-2);
}

.input::placeholder {
  color: var(--secondary-color-blue-3);
  font-size: 16px;
  line-height: 1.25;
}

.iconeye {
  position: absolute;
  top: 14px;
  right: 10px;
  width: 16px;
  height: 16px;
  stroke: var(--primary-color-blue);
  cursor: pointer;
}

.button {
  display: block;
  margin-left: auto;
  width: 256px;
  height: 36px;
  padding: 8px 30px;
  margin-top: 24px;

  color: var(--primary-color-white);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;

  border: transparent;
  border-radius: 10px;
  background: var(--primary-color-blue);
  box-shadow: 0px 4px 8px 0px rgba(64, 123, 255, 0.34);
}

.button:hover {
  box-shadow: 0px 4px 14px 0px rgba(64, 123, 255, 0.54);
}

.button:active {
  box-shadow: none;
}

@media only screen and (min-width: 768px) {
  .button {
    width: 160px;
    height: 44px;
    font-size: 18px;
  }
}

.errormessage {
  position: absolute;
  bottom: -17px;
  left: 0;
  color: var(--secondary-color-red);
  font-size: 14px;
  line-height: 1.28;
}

.errorinput {
  color: var(--secondary-color-red);
  font-size: 16px;
  line-height: 1.25;

  min-width: 256px;
  height: 44px;
  padding: 12px 10px;
  border-radius: 6px;
  border: 1px solid var(--secondary-color-red);
  outline: var(--secondary-color-blue-2);
}

.errorinput::placeholder {
  color: var(--secondary-color-red);
}
