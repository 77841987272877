.footer {
  width: 100%;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  background-color: transparent;
}

.content {
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;


}

.footer-text {
  display: block;

  color: var(--primary-color-blue);
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.vr {
  display: inline;
  height: 12px;
  border-left: 2px solid var(--primary-color-blue);
}

.footer-btn {
  display: block;
  padding: 0;
  border: none;
  color: var(--primary-color-blue);
  background-color: transparent;

  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  transition-property: all;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.footer-btn:hover,
.footer-btn:focus {
  color: var(--secondary-color-orange);
  transform: scale(1.1);
}

.swipper-container {
  width: 280px;
}

.swiper-slide {
  text-align: center;
}

.swiper-slide img {
  display: block;
  margin-bottom: 14px;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.swipper-info-name {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.11;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.swipper-info-role {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  margin-bottom: 12px;
}

.swipper-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.swipper-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 14px;
}

.swiper-info-task {
  margin-bottom: 3px;
}

@media only screen and (min-width: 768px) {
  .footer-text {
    font-size: 14px;
  }

  .footer-btn {
    font-size: 14px;
  }

  .vr {
    height: 16px;
  }

  .swipper-container {
    width: 630px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    gap: 40px;
  }

  .swiper-slide img {
    margin: 0;
    width: 200px;
    height: 200px;

  }

  .swipper-info-container {
    width: 225px;
  }

}

@media only screen and (min-width: 1440px) {
  .footer-text {
    font-size: 14px;
  }

  .footer-btn {
    font-size: 14px;
  }

  .swipper-container {
    width: 540px;
  }

  .swiper-slide {
    gap: 20px;
  }
}