.backdrop {
  background-color: var(--backdrop-color-black);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.modal {
  background-color: var(--primary-color-white);
  border-radius: 8px;
  position: relative;
  padding: 32px 24px;
  @media only screen and (max-width: 767px) {
    padding: 32px 12px;
  }
}

.p24 {
  @media only screen and (max-width: 767px) {
    padding: 32px 24px;
  }
}

.container {
  min-width: 280px;
  min-height: 100px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;

  max-height: calc(100vh - 20px);
  overflow: auto;
}

/* @media only screen and (min-width: 768px) {
  .container {
    width: 704px;
  }
}

@media only screen and (min-width: 1440px) {
  .container {
    width: 592px;
  }
}

@media only screen and (min-width: 1440px) {
  .settingModal {
    width: 1008px;
  }
} */

.btnClose {
  position: absolute;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  top: 36px;
  right: 24px;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  scale: 1;
}

.btnClose:hover,
.btnClose:hover {
  scale: 1.1;
}

.title {
  height: 32px;
  color: var(--primary-color-black);
  font-size: 26px;
  font-weight: 500;
  line-height: 1.23;

  @media only screen and (max-width: 767px) {
    max-width: calc(280px - 2 * 24px);
  }
}

.content {
  margin-top: 24px;
}
